import React, { useEffect } from 'react';
import StatCard from '../components/Services/StatCard';
import FeatureSection from '../components/Services/FeatureSection';
import TestimonialCard from '../components/Services/TestimonialCard';
import { FaUser, FaGlobe, FaProjectDiagram } from 'react-icons/fa';
import Slider from 'react-slick';

import softwaredev from '../assets/svgs/softwaredev.svg';
import appdev from '../assets/svgs/appdev.svg';
import cloud from '../assets/svgs/cloud.svg';
import twin from '../assets/svgs/vr.svg';
import aiml from '../assets/svgs/ai.svg';
import iot from '../assets/svgs/iot.svg';
import gamedev from '../assets/svgs/gamedev.svg';

import { useAnimation, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';


const Services = () => {
  const controlsService = useAnimation();
  const controlsHeading = useAnimation();
  const controlsParagraph = useAnimation();
  const { ref: refService, inView: inViewService } = useInView({ triggerOnce: true, threshold: 0.1 });
  const { ref: refHeading, inView: inViewHeading } = useInView({ triggerOnce: true, threshold: 0.1 });
  const { ref: refParagraph, inView: inViewParagraph } = useInView({ triggerOnce: true, threshold: 0.1 });
  useEffect(() => {
    if (inViewService) {
      controlsService.start('visible');
    }
  }, [controlsService, inViewService]);
  useEffect(() => {
    if (inViewHeading) {
      controlsHeading.start('visible');
    }
  }, [controlsHeading, inViewHeading]);
  useEffect(() => {
    if (inViewParagraph) {
      controlsParagraph.start('visible');
    }
  }, [controlsParagraph, inViewParagraph]);

  const stats = [
    { IconComponent: FaUser, number: "45+", label: "Clients" },
    { IconComponent: FaGlobe, number: "8+", label: "Countries" },
    { IconComponent: FaProjectDiagram, number: "100+", label: "Projects" }
  ];

  const features = [
    {
      image: softwaredev,
      title: "Software Development",
      description: "Our software development services are tailored to create robust, scalable, and high-performance applications. We follow a meticulous development process that includes requirement analysis, design, development, testing, and deployment. Our team of seasoned developers is proficient in various programming languages and frameworks, ensuring that we can tackle any challenge and deliver solutions that meet your specific requirements.",
      features: [
        {
          title: "Custom Software Development:",
          description: "Tailored solutions designed to fit your unique business processes."
        },
        {
          title: "Enterprise Application Development:",
          description: "Scalable and secure applications to streamline your operations."
        },
        {
          title: "Software Integration:",
          description: "Seamless integration of new software with existing systems to ensure smooth transitions and enhanced productivity."
        },
        {
          title: "Maintenance and Support:",
          description: "Ongoing support and maintenance to keep your software running smoothly and efficiently."
        },
      ],
      imageOnLeft: true
    },
    {
      image: appdev,
      title: "App Development",
      description: "In the mobile-first world, having a strong app presence is crucial. We specialize in developing custom mobile applications for both iOS and Android platforms. Our app development process involves thorough market research, user-centric design, agile development, and rigorous testing. We focus on creating apps that are not only visually appealing but also provide a seamless user experience, ensuring high user engagement and satisfaction.",
      features: [
        {
          title: "IOS and Android App Development:",
          description:"Native apps built to leverage the full power of mobile operating systems."
        },
        {
          title: "Cross-Platform App Development:",
          description:"Single codebase apps that work seamlessly across multiple platforms, reducing time and costs."
        },
        {
          title: "UI/UX Design:",
          description:"Engaging and intuitive designs that prioritize user experience and functionality."
        },
        {
          title: "App Maintenance and Support:",
          description:"Continuous updates and enhancements to ensure your app stays relevant and functional."
        },
      ],
      imageOnLeft: false
    },
    {
      image: gamedev,
      title: "Game Development",
      description: "Our game development services are designed to turn your creative concepts into entertaining and immersive experiences. We strive to create high-quality games that attract gamers across several platforms, including console, PC, and mobile. Our expert developers and designers collaborate with you to guarantee that every component of your game reflects your vision and meets the highest industry standards.",
      features: [
        {
          title: "Custom Game Development:",
          description:"Tailored game development solutions that bring your unique ideas to life."
        },
        {
          title: "Cross-Platform Compatibility:",
          description:"Create games that work effortlessly across many platforms, such as console, PC, and mobile, delivering a consistent experience for all gamers."
        },
        {
          title: "Game Design and Art:",
          description:"Outstanding design and artwork that improves the visual appeal and overall experience of your game, from characters to surroundings."
        },
        {
          title: "Post-Launch Support:",
          description:"Continuous updates, bug fixes, and new features will keep your game entertaining and up to date long after it is released."
        },
      ],
      imageOnLeft: true
    },
    {
      image: aiml,
      title: "Artificial Intelligence and Machine Learning",
      description: "Unlock the potential of your data with our AI and ML solutions. We provide a range of services including data analysis, predictive modelling, natural language processing, and computer vision. Our AI/ML solutions are designed to automate processes, enhance decision-making, and provide actionable insights. By leveraging the latest advancements in AI and ML, we help you stay ahead of the competition and drive innovation.",
      features: [
        {
          title: "Predictive Analytics:",
          description: "Gain insights into future trends and behaviours to make informed decisions."
        },
        {
          title: "Natural Language Processing (NLP):",
          description: "Enhance your customer interactions with intelligent language processing capabilities."
        },
        {
          title: "Computer Vision:",
          description: "Automate visual tasks with cutting-edge image and video analysis solutions."
        },
        {
          title: "AI/ML Model Development:",
          description: "Custom-built models tailored to your specific business needs."
        },
      ],
      imageOnLeft: false
    },
    {
      image: cloud,
      title: "Cloud Services",
      description: "Embrace the power of the cloud with our end-to-end cloud services. We offer cloud strategy consulting, migration, deployment, and management services. Our cloud solutions are designed to enhance your business agility, reduce operational costs, and improve scalability. Whether you are looking to migrate to the cloud, optimize your existing cloud infrastructure, or develop cloud-native applications, our experts are here to help.",
      features: [
        {
          title: "Cloud Migration:",
          description:"Seamless transition of your applications and data to the cloud, minimizing downtime and disruption."
        },
        {
          title: "Cloud Management:",
          description:"End-to-end management of your cloud infrastructure to ensure optimal performance and cost-efficiency."
        },
        {
          title: "Cloud Security:",
          description:"Robust security measures to protect your data and applications in the cloud."
        },
        {
          title: "DevOps and Automation:",
          description:"Streamlining your cloud operations through automation and DevOps best practices, enabling faster delivery and greater agility."
        },
      ],
      imageOnLeft: true
    },
    
    // {
    //   image: twin,
    //   title: "Blockchain",
    //   description: "Create a virtual replica of your physical assets with our Digital Twins technology. By mirroring the real-world performance of your products or systems, you can optimize operations, predict issues, and improve overall efficiency. By integrating IoT, AI, and cloud technologies, we provide real-time monitoring, predictive maintenance, and optimization of your operations. Digital Twins help you gain deeper insights into your assets, improve operational efficiency, and reduce downtime. Whether you are in manufacturing, healthcare, or any other industry, our Digital Twin solutions can transform the way you manage your assets.",
    //   features: [
    //     {
    //       title: "Real-Time Monitoring:",
    //       description: "Track and analyze the performance of your physical assets in real-time."
    //     },
    //     {
    //       title: "Predictive Maintenance:",
    //       description: "Identify potential issues before they occur, reducing downtime and maintenance costs."
    //     },
    //     {
    //       title: "Simulation and Testing:",
    //       description: "Simulate different scenarios to test and optimize your systems."
    //     },
    //     {
    //       title: "Integration with IoT:",
    //       description: "Enhance your Digital Twins with data from IoT devices for a comprehensive view of your operations."
    //     },
    //   ],
    //   imageOnLeft: false
    // },
    {
      image: iot,
      title: "Internet of Things (IoT)",
      description: "Connect and manage your devices with our comprehensive IoT solutions. We offer IoT strategy consulting, device integration, data analytics, and IoT application development. Our IoT solutions are designed to improve operational efficiency, reduce costs, and provide valuable data insights. By connecting your devices and systems, we help you create smart, connected environments that drive business growth.",
      features: [
        {
          title: "IoT Consulting and Strategy:",
          description: "Develop a clear IoT strategy aligned with your business goals."
        },
        {
          title: "IoT Development and Integration:",
          description: "Design, build, and integrate IoT solutions that connect your devices and systems."
        },
        {
          title: "IoT Analytics:",
          description: "Extract valuable insights from your IoT data to inform decision-making."
        },
        {
          title: "IoT Security:",
          description: "Implement robust security measures to protect your IoT ecosystem from threats."
        },
      ],
      imageOnLeft: false
    },
  ];

  const testimonials = [
    {
      name: "Viezh Robert",
      location: "Warsaw, Poland",
      rating: 4.5,
      image: "",
      testimonial: "It has been an amazing experience to work with this organization. They consistently deliver excellent work.",
      isHighlighted: true
    },
    {
      name: "Yessica Christy",
      location: "Shanxi, China",
      rating: 4.5,
      image: "",
      testimonial: "The group provided us with precisely what we needed and showed a deep understanding of our needs. Extremely happy with the service they provided.",
      isHighlighted: false
    },
    {
      name: "Kim Young Jou",
      location: "Seoul, South Korea",
      rating: 4.5,
      image: "",
      testimonial: "They are committed to producing high-quality work, and I appreciate that. Collaborating with them was enjoyable.",
      isHighlighted: false
    }
  ];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1, 
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const textAnimation = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { duration: 1 }
    }
  };

  

  return (
    <main>
      <div ref={refService} className='flex max-md:mt-28 mt-36 mb-36 max-md:mb-10'>
        <motion.h2 initial="hidden" animate={controlsService}  variants={textAnimation} className='text-4xl font-bold leading-10 text-center w-full max-md:text-3xl max-md:mt-5 max-md:max-w-full'>OUR SERVICES</motion.h2>
      </div>
      
      {features.map((feature, index) => (
        <FeatureSection key={index} {...feature} />
      ))}

      <section className="mt-56 px-28 text-center font-code max-md:mt-28 max-md:px-5 ">
        <h2 className=' uppercase text-4xl font-bold max-md:text-2xl max-md:max-w-full'>Elevating Your Business with Cutting-Edge Solutions</h2>
      <p className="mt-10 text-lg font-code text-center max-md:text-sm max-md:max-w-full">
        We offer a suite of innovative services tailored to meet your business needs,<br /> from AI & ML to custom software development.
      </p>
      <div className="flex gap-5 justify-evenly items-center px-20 mb-48 max-md:mb-20 mt-20 w-full whitespace-nowrap rounded-xl max-md:gap-2 max-md:justify-center max-md:px-5 max-md:mt-10 max-md:flex-nowrap max-md:overflow-hidden">
        {stats.map((stat, index) => (
          <React.Fragment key={index}>
            <StatCard {...stat} />
          </React.Fragment>
        ))}
      </div>
      </section>

      {/* <section className="flex flex-col items-center font-code self-stretch mt-20 py-10 px-28 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <motion.h2 ref={refHeading} initial="hidden" animate={controlsHeading}  variants={textAnimation} className="uppercase mt-5 text-4xl max-md:text-3xl font-bold text-center leading-[50px]">
          Trusted by Thousands of Happy Customers
        </motion.h2>
        <motion.p ref={refParagraph} initial="hidden" animate={controlsParagraph} variants={textAnimation} className="mt-8 text-lg max-md:text-sm leading-7 text-center max-md:max-w-full">
          Our commitment to excellence has earned us the trust of clients worldwide,<br /> delivering reliable and innovative solutions.
        </motion.p>
        <div className="mt-20 w-full max-w-[1300px] max-md:mt-10 max-md:max-w-full">
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div key={index} className="flex flex-col items-center px-5">
                <TestimonialCard {...testimonial} />
              </div>
            ))}
          </Slider>
        </div>
      </section> */}
    </main>
  );
};

export default Services;
