import Lottie from 'lottie-react';
import React from 'react';
import map from '../../assets/animations/map.json'
const Hero = () => {
  return (
    <section className="mt-36 font-code max-md:mt-32 max-md:max-w-full ">
      <h1 className="uppercase text-4xl max-md:text-3xl font-bold text-center">
        Reach Out to Us
      </h1>
      <p className="mt-8 text-lg max-md:text-sm px-96 max-md:px-5 text-center max-md:max-w-full">
        We'd love to hear from you! Whether you have questions, feedback, or just want to say hello, feel free to get in touch.
      </p>

    </section>
  );
};

export default Hero;